import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { useTranslation } from "localization";
import { MainButton, TextInput } from "components";
import { FormContainer } from "../FormContainer";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { EMAIL_REGEX, PATHNAMES } from "constants/global";
import { login } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";

export const SignInForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const loading = useAppSelector((state) => state.auth.loginLoading);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    navigate(PATHNAMES.forgot_password);
  };

  const navigateHome = () => {
    navigate(PATHNAMES.home);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    dispatch(login({ data, navigate: navigateHome })).finally(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  return (
    <div className="sign_up_form">
      <FormContainer>
        <div className="sign_up_content">
          <h1 className="form_title">{translation.login}</h1>
          <form
            className="form_content"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <TextInput
              type="email"
              name="email"
              label={translation.email}
              register={register}
              errors={errors}
              pattern={EMAIL_REGEX}
              patternError={translation.email_pattern_error}
              setValue={setValue}
            />
            <div className="form_element">
              <TextInput
                type={showPassword ? "text" : "password"}
                name="password"
                label={translation.password}
                isPassword={true}
                showPassword={showPassword}
                handleShowPassword={handleShowPassword}
                register={register}
                setValue={setValue}
                errors={errors}
                required={true}
              />
              <button
                className="forgot_password_btn"
                type="button"
                onClick={handleForgotPassword}
              >
                {translation.forgot_password}
              </button>
            </div>
            <div className="sign_up_button">
              <MainButton
                name={translation.login}
                customClass="custom_button width_100"
                type="submit"
                loading={loading}
                disabled={loading}
              />
            </div>
          </form>
          <div className="sign_in_section">
            <p className="have_account_text">{translation.dont_have_account}</p>
            <Link to="/sign-up" className="sign_in_link">
              {translation.sign_up}
            </Link>
          </div>
        </div>
      </FormContainer>
    </div>
  );
};
